import React from 'react'
import removeMarkdown from 'remove-markdown'
import yaml from 'js-yaml'

import Badge from 'react-bootstrap/Badge'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import RedirectToParent from 'components/redirect-to-parent'
import WorkDetail from 'components/work-detail'
import Wrapper from 'components/wrapper'

import { useGithubData } from 'utils/use-github-data'

export default function Work({ location, pageContext }) {
  // params
  const { projectData } = pageContext ?? {}
  if (!projectData) return <RedirectToParent location={location} />

  // get the work data from Github
  const workData = Object.assign({}, projectData)
  const github = useGithubData()
  const workRepo = github.find((o) => o.type === 'tree' && o.name === projectData.contentKey)
  if (workRepo?.object?.entries) {
    const creditsFile = workRepo.object.entries.find(({ name }) => name === 'credits.yml')
    workData.credits = yaml.load(creditsFile?.object?.text)
    const descriptionFile = workRepo.object.entries.find(({ name }) => name === 'description.md')
    workData.description = descriptionFile?.object?.text
    const noteFile = workRepo.object.entries.find(({ name }) => name === 'note.md')
    workData.note = noteFile?.object?.text
    const taglineFile = workRepo.object.entries.find(({ name }) => name === 'tagline.md')
    workData.tagline = taglineFile?.object?.text
    const venuesFile = workRepo.object.entries.find(({ name }) => name === 'venues.yml')
    workData.venues = yaml.load(venuesFile?.object?.text)
  }

  const workTitle = workData.title
  const description =
    workTitle +
    (workData.year ? ` (${workData.year})` : '') +
    ' - ' +
    removeMarkdown(workData.tagline)

  const renderHeader = () => {
    return (
      <div className="my-2">
        <Row className="pt-1 align-items-end border-bottom border-secondary">
          <Col xs="12" lg="8" className="fs-3 media-title text-center text-lg-start">
            {workTitle}
          </Col>
          <Col xs="12" lg="4" className="fs-4 mb-2 text-center text-lg-end">
            {(workData.tags ?? []).map((tag, index) => (
              <Badge key={index} bg="dark" className="media-title p-1 me-2">
                {tag}
              </Badge>
            ))}
            {workData.year && (
              <Badge bg="dark" className="media-title p-1">
                {workData.year}
              </Badge>
            )}
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <Wrapper location={location} variant="project" wrapperClass="d-flex flex-column w-100 mx-auto">
      <Meta title={workTitle} description={description} />
      {renderHeader()}
      <WorkDetail workData={workData} />
    </Wrapper>
  )
}
